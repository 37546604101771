import React, { HTMLAttributes } from "react";

const LockIconPrivate = (props: HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none">
        <path d="M0 0h32v32H0z" />
        <path
          d="M13.425 8.076c0-.63.522-1.143 1.164-1.143h2.821c.642 0 1.165.513 1.165 1.143v2.44h-5.15v-2.44zm8.72 2.44h-.584v-2.44C21.561 5.828 19.7 4 17.411 4h-2.822c-2.289 0-4.15 1.828-4.15 4.076v2.44h-.585c-2.497 0-4.52 1.987-4.52 4.438v8.606c0 2.452 2.023 4.44 4.52 4.44h12.291c2.498 0 4.522-1.988 4.522-4.44v-8.606c0-2.451-2.024-4.439-4.522-4.439z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default LockIconPrivate;
