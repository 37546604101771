import React, { HTMLProps } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ maxWidth: number }>()((theme: Theme, { maxWidth }) => ({
  container: {
    padding: theme.spacing(0, 2.5),
    margin: "0 auto",
    boxSizing: "content-box",
    maxWidth,
    [theme.breakpoints.down("md")]: {
      maxWidth: 664,
    },
  },
}));

type MaxWidthContainerProps = {
  maxWidth?: number;
} & HTMLProps<HTMLDivElement>;

export const MaxWidthContainer = ({
  maxWidth = 812,
  className,
  ...props
}: MaxWidthContainerProps) => {
  const { classes, cx } = useStyles({ maxWidth });

  return <div className={cx(classes.container, className)} {...props} />;
};
