import React, { useEffect, useState } from "react";

import { Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import { MainLayout } from "@/common/components/MainLayout/MainLayout";
import { MaxWidthContainer } from "@/common/components/MaxWidthContainer";
import SEO from "@/common/components/SEO";

import AlertAtomIcon from "@/common/icons/AlertAtomIcon";
import LockIconPrivate from "@/common/icons/LockIconPrivate";

import { activityTypeUrl } from "@/common/constants";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20vh",
    textAlign: "center",
  },
  message: {
    marginBottom: "30px",
  },
  activityNothingToSee: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    lineHeight: "1.29",
    fontSize: "1.065rem",
    color: "#6c6b78",
  },
  activityDescription: {
    marignTop: `16px !important`,
    maxWidth: "391px",
    color: "#6c6b78",
    lineHeight: "1.47",
    fontSize: "0.9375rem",
    fontWeight: "normal",
  },
}));

const notFoundTypeToActivityType = {
  blog: "Blog",
  debate: "Debate",
  event: "Event",
  group: "Group",
  livestream: "LiveStream",
  petition: "Petition",
} as const;

const PageNotFound = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [notFoundType, setNotFoundType] = useState<string | null>(null);
  const [errorType, setErrorType] = useState<string | null>(null);
  useEffect(() => {
    // We have to get the asPath from the window location because Next.js renders the
    // 404 page statically not including the router location, which would cause
    // inconsistencies (like breaking the SVG images) between SSR and CSR rendered versions
    // if we used Next.js router "the conventional way"
    const params = new URLSearchParams(window.location.search);

    setNotFoundType(params.get("type") || null);
    setErrorType(params.get("error_type") || null);
  }, []);

  const activityType =
    notFoundTypeToActivityType[notFoundType as keyof typeof notFoundTypeToActivityType];

  const getErrorTitle = () => {
    switch (errorType) {
      case "blocked_by_admin":
        return t("App.Activity.NotAuthorizedErrorTitle", {
          activity: t(`Common.${activityType.toLowerCase()}`),
        });
      case "blocked_by_me":
        return t("App.Activity.AdminBlockedErrorTitle");
      default:
        return t("Common.404.NothingToSee");
    }
  };

  const getErrorSubtitle = () => {
    switch (errorType) {
      case "blocked_by_admin":
        return t("App.Activity.NotAuthorizedErrorSubtitle", {
          activity: t(`Common.${activityType.toLowerCase()}`),
        });
      case "blocked_by_me":
        return t("App.Activity.AdminBlockedErrorSubtitle");
      default:
        return t(`Common.404.ActivityDescription.${notFoundType}`);
    }
  };

  return (
    <MainLayout>
      <SEO />

      <MaxWidthContainer>
        <Container className={classes.container}>
          {errorType === "blocked_by_admin" ? <LockIconPrivate /> : <AlertAtomIcon />}
          <Typography className={classes.activityNothingToSee} variant="h2">
            {getErrorTitle()}
          </Typography>
          {notFoundType && (
            <Typography className={classes.activityDescription} variant="h3">
              {getErrorSubtitle()}
            </Typography>
          )}
          {activityType ? (
            <>
              {errorType === "blocked_by_me" ? (
                <Button href={"/settings/privacy"} variant="text" uppercase={true}>
                  {t("Settings.Privacy.BlockedUsers.Unblock")}
                </Button>
              ) : (
                <Button
                  href={activityTypeUrl[activityType as keyof typeof activityTypeUrl]}
                  variant="text"
                  uppercase={true}>
                  {t("Common.404.Discover", {
                    activityType: t(`Common.${activityType.toLowerCase()}_plural`),
                  })}
                </Button>
              )}
            </>
          ) : (
            <Button href="/" color="primary">
              {t("Common.404.GoHome")}
            </Button>
          )}
        </Container>
      </MaxWidthContainer>
    </MainLayout>
  );
};

export default PageNotFound;
