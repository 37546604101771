import React, { HTMLAttributes } from "react";

const AlertAtomIcon = (props: HTMLAttributes<SVGElement> & { width?: number; height?: number }) => (
  <svg
    {...props}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.8008296,25.8953556 L23.4981304,10.276618 C22.7336822,9.06592027 21.4122825,8.33333333 19.9929413,8.33333333 C18.5736001,8.33333333 17.2522004,9.06592027 16.4877522,10.276618 L7.185053,25.8953556 C6.51621198,27.0240883 6.49309391,28.4283499 7.12440959,29.5790202 C7.85452427,30.8746115 9.21688599,31.6721271 10.6902421,31.6664301 L29.2956405,31.6664301 C30.7589711,31.6822384 32.1189932,30.9048251 32.861473,29.6281357 C33.5116747,28.4648814 33.4884662,27.0363336 32.8008296,25.8953556 L32.8008296,25.8953556 Z M19.9929413,26.7548774 C19.3230927,26.7548774 18.7800731,26.2051331 18.7800731,25.5269892 C18.7800731,24.8488453 19.3230927,24.299101 19.9929413,24.299101 C20.6627899,24.299101 21.2058095,24.8488453 21.2058095,25.5269892 C21.2058095,26.2051331 20.6627899,26.7548774 19.9929413,26.7548774 Z M21.2058095,21.8433247 C21.2058095,22.5214686 20.6627899,23.0712128 19.9929413,23.0712128 C19.3230927,23.0712128 18.7800731,22.5214686 18.7800731,21.8433247 L18.7800731,16.9317719 C18.7800731,16.253628 19.3230927,15.7038838 19.9929413,15.7038838 C20.6627899,15.7038838 21.2058095,16.253628 21.2058095,16.9317719 L21.2058095,21.8433247 Z"
      fill="currentColor"></path>
  </svg>
);

export default AlertAtomIcon;
